import { Box, Grid, Paper, Typography } from "@mui/material";
import ReactApexChart from "react-apexcharts";
import { useTranslation } from "react-i18next";

const ChartRangeInMilliseconds = 10_000;

const options: any = {
  chart: {
    id: "realtime",
    height: 350,
    type: "area",
    sparkline: {
      enabled: true,
    },
    offsetX: 0,
    offsetY: 0,
    animations: {
      enabled: true,
      easing: "linear",
      dynamicAnimation: {
        enabled: true,
        speed: 1000,
      },
    },
    toolbar: {
      show: false,
    },
    zoom: {
      enabled: false,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    curve: "smooth",
  },
  markers: {
    size: 0,
  },
  grid: {
    show: false,
  },
  xaxis: {
    show: false,
    type: "datetime",
    range: ChartRangeInMilliseconds,
    lines: {
      show: false,
    },
    labels: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: false,
    lines: {
      show: false,
    },
    min: 0,
  },
  tooltip: {
    enabled: false,
  },
  legend: {
    show: false,
  },
  fill: {
    colors: ["#ADD8E6"],
  },
};

interface Props {
  numberOfEggsSortedThisSecond: number;
}

export default function RealtimeEggsPresentation({
  numberOfEggsSortedThisSecond,
}: Props) {
  const { t } = useTranslation();

  return (
    <>
      <Paper
        sx={{
          root: {
            padding: 0,
          },
        }}
      >
        <Box
          pt={1}
          sx={{
            display: "flex",
          }}
        >
          <Grid container gap={0}>
            <Grid item xs={12}>
              <Box
                sx={{
                  display: "flex",
                }}
              ></Box>
              <Typography variant="h3" pl={2}>
                {numberOfEggsSortedThisSecond}
              </Typography>
              <Typography
                variant="h6"
                pl={2}
                pb={3}
                sx={{
                  display: "flex",
                  fontWeight: "300",
                  fontSize: "18px",
                }}
              >
                {t("widgets.realtimeEggs.description")}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <ReactApexChart
                options={options}
                series={[
                  {
                    data: [],
                  },
                ]}
                type="area"
                height={150}
                width="100%"
              />
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </>
  );
}
