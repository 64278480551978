import styled from "@emotion/styled";
import { AppBar, Toolbar, Typography, useTheme } from "@mui/material";
import iMobaLogoImage from "./logo.png";
import { AppRoutes } from "./utility/AppRoutes";

const Logo = styled("img")`
  height: 40px;
  width: 40px;
`;

function App() {
  const theme = useTheme();
  return (
    <>
      <AppBar
        position="static"
        sx={{ backgroundColor: theme.basic.mobaBlue, boxShadow: "none" }}
      >
        <Toolbar disableGutters sx={{ marginLeft: theme.spacing(2) }}>
          <Logo src={iMobaLogoImage} alt="iMoba logo" />
          <Typography
            variant="h6"
            noWrap
            sx={{
              display: "flex",
              marginLeft: theme.spacing(1),
            }}
          >
            iMoba Global Overview
          </Typography>
        </Toolbar>
      </AppBar>
      <AppRoutes />
    </>
  );
}

export default App;
