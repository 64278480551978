import {
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  useCallback,
} from "react";
import { EggPeriod } from "../components/realtime-eggs";

/**
 * Cleans ApexCharts data periodically to prevent memory leaks
 *
 * @param setRealtimeEggPeriods
 * @param setDailyTotalsPerSecond
 * @param realtimeEggPeriods
 */
export default function useApexChartCleanup(
  setRealtimeEggPeriods: Dispatch<SetStateAction<EggPeriod[]>>,
  realtimeEggPeriods: EggPeriod[]
) {
  const numberOfDataPointsToRemove = 20;
  const maximumNumberOfDataPoints = 30;
  const [isFlushing, setIsFlushing] = useState(false);

  /**
   * Removes the oldest data points from the data set
   */
  const flush = useCallback(() => {
    setRealtimeEggPeriods((prev) =>
      prev.slice(prev.length - numberOfDataPointsToRemove, prev.length - 1)
    );
  }, [setRealtimeEggPeriods]);

  /**
   * Updates the chart with the new data
   * If the chart is being flushed, disable animations. This prevents the chart
   * from displaying jagged animations.
   *
   * Otherwise, enable animations.
   */
  useEffect(() => {
    if (!isFlushing) {
      updateChart(realtimeEggPeriods, true);
    }
    if (isFlushing) {
      updateChart(realtimeEggPeriods, false);
      setIsFlushing(false);
    }
  }, [isFlushing, realtimeEggPeriods]);

  /**
   * The chart should be flushed every so often.
   * This prevents memory leaks, as the chart is typically running for a long time.
   */
  useEffect(() => {
    if (realtimeEggPeriods.length > maximumNumberOfDataPoints) {
      setIsFlushing(true);
      flush();
    }
  }, [realtimeEggPeriods, flush]);
}

const updateChart = (
  data: Array<{ x: number; y: number }>,
  disableAnimations: boolean
) => {
  const chartSeries: ApexAxisChartSeries = [{ data: data }];

  ApexCharts.getChartByID("realtime")?.updateSeries(
    chartSeries,
    disableAnimations
  );
};
