import { useMemo, useState } from "react";
import { EggPeriod } from ".";
import useApexChartCleanup from "../../hooks/useApexChartFlushing";
import useInterval from "../../hooks/useInterval";
import RealtimeEggsPresentation from "./RealtimeEggsPresentation";
import { staticData } from "./static-data";

export default function RealtimeEggsContainer() {
  const [realtimeEggPeriods, setRealtimeEggPeriods] = useState<
    Array<EggPeriod>
  >([]);

  useInterval(() => {
    const currentSecond = new Date().getSeconds();
    const eggsSortedThisSecond = staticData[currentSecond];

    setRealtimeEggPeriods((prev) => [
      ...prev,
      { x: new Date().getTime(), y: eggsSortedThisSecond },
    ]);
  }, 1000);

  const numberOfEggsSortedThisSecond = useMemo(() => {
    if (realtimeEggPeriods.length === 0) return 0;
    return realtimeEggPeriods[realtimeEggPeriods.length - 1].y;
  }, [realtimeEggPeriods]);

  useApexChartCleanup(setRealtimeEggPeriods, realtimeEggPeriods);

  return (
    <RealtimeEggsPresentation
      numberOfEggsSortedThisSecond={numberOfEggsSortedThisSecond}
    />
  );
}
