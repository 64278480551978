import { AxiosError } from "axios";
import React, { useMemo } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { useNavigate } from "react-router-dom";

interface Props {
  children?: React.ReactNode;
}

export function ReactQueryProvider({ children }: Props) {
  const navigate = useNavigate();

  const queryClient = useMemo(
    () =>
      new QueryClient({
        defaultOptions: {
          queries: {
            onError: (error) => {
              if (error instanceof AxiosError) {
                if (error.code === "ERR_NETWORK") {
                  return navigate("/no-access", { replace: true });
                }
              }
            },
          },
        },
      }),
    [navigate]
  );

  return (
    <QueryClientProvider client={queryClient}>
      {children}
      {process.env.NODE_ENV === "development" && (
        <ReactQueryDevtools position="bottom-right" />
      )}
    </QueryClientProvider>
  );
}
