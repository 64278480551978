import { useTranslation } from "react-i18next";
import TotalWidgetBasePresentation from "../total-widget-base/TotalWidgetBasePresentation";
import { useGlobalDashboardData } from "../../../hooks/useGlobalDashboardData";

export default function TotalUsersContainer() {
  const { t } = useTranslation();

  const { isLoading, totalUsers } = useGlobalDashboardData();

  return (
    <TotalWidgetBasePresentation
      totalEggs={totalUsers ?? 0}
      isLoading={isLoading}
      unit={t("widgets.totalUsers.unit")}
      description={t("widgets.totalUsers.description")}
    />
  );
}
