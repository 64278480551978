import { Route, Routes } from "react-router-dom";
import NotFoundPage from "../pages/NoAccessPage";
import RootPage from "../pages/RootPage";

export function AppRoutes() {
  return (
    <Routes>
      <Route path="/" element={<RootPage />} />
      <Route path="/no-access" element={<NotFoundPage />} />
    </Routes>
  );
}
