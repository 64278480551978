import { useQuery } from "react-query";
import { Queries } from "../api/Queries";
import { getData } from "../api/Services/GlobalDashboardService";
import { DispatchActionType, useGlobalState } from "../state/GlobalState";
import HomePage from "./HomePage";
import LoadingPage from "./LoadingPage";

export default function RootPage() {
  const { state, dispatch } = useGlobalState();

  useQuery([Queries.GetData], {
    queryFn: async () => await getData(),
    onSuccess: (data) => {
      if (state.displayLoadingScreenState) {
        dispatch({ Type: DispatchActionType.Disable });
      }

      return data;
    },
  });

  return state.displayLoadingScreenState ? <LoadingPage /> : <HomePage />;
}
