import { useTranslation } from "react-i18next";
import { Box, Button, Typography } from "@mui/material";
import DoNotDisturbIcon from "@mui/icons-material/DoNotDisturb";
import { Link } from "react-router-dom";

export default function NotFoundPage() {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <DoNotDisturbIcon
          sx={{
            fontSize: 96,
          }}
        />
        <Typography variant="h2">{t("accessDenied.title")}</Typography>
        <Typography variant="body2">{t("accessDenied.message")}</Typography>
        <Button
          component={Link}
          to="/"
          sx={(theme) => ({
            marginTop: theme.spacing(1),
          })}
          variant="contained"
          color="primary"
        >
          {t("accessDenied.tryAgain")}
        </Button>
      </Box>
    </Box>
  );
}
