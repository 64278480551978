import { Box, Paper } from "@mui/material";
import { Icon, LatLngExpression } from "leaflet";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import { Location } from "../../api/ApiContracts";

const position: LatLngExpression = { lat: 0, lng: 0 };

const machineMarker = new Icon({
  iconUrl: "/svg/machine_marker.svg",
  iconSize: [25, 25],
});

const officeMarker = new Icon({
  iconUrl: "/svg/office_marker.svg",
  iconSize: [25, 25],
});

interface Props {
  machineLocations: Array<Location>;
  offices: Array<Location>;
}

export default function MachineMapPresentation({
  machineLocations,
  offices,
}: Props) {
  return (
    <Paper>
      <Box pt={1} pb={1} px={1}>
        <MapContainer
          center={position}
          zoom={2}
          scrollWheelZoom={false}
          style={{ height: "500px" }}
          zoomControl={false}
          attributionControl={false}
        >
          <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
          {machineLocations.map((machineLocation: Location) => {
            return (
              <Marker
                key={machineLocation.id}
                position={[machineLocation.latitude, machineLocation.longitude]}
                icon={machineMarker}
              />
            );
          })}
          {offices.map((officeLocation: Location) => {
            return (
              <Marker
                key={officeLocation.id}
                position={[officeLocation.latitude, officeLocation.longitude]}
                icon={officeMarker}
                zIndexOffset={100}
              />
            );
          })}
        </MapContainer>
      </Box>
    </Paper>
  );
}
