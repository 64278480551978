import { useGlobalDashboardData } from "../../hooks/useGlobalDashboardData";
import MachineMapPresentation from "./MachineMapPresentation";
import { staticMobaOffices } from "./static-data";

export default function MachineMapContainer() {
  const { machineLocations } = useGlobalDashboardData();

  return (
    <MachineMapPresentation
      machineLocations={machineLocations ?? []}
      offices={staticMobaOffices}
    />
  );
}
