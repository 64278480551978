import {
  Paper,
  Box,
  Typography,
  CircularProgress,
  Skeleton,
} from "@mui/material";

interface Props {
  totalEggs: number;
  isLoading: boolean;
  unit: string;
  description: string;
}

export default function TotalWidgetBasePresentation({
  totalEggs,
  isLoading,
  unit,
  description,
}: Props) {
  if (isLoading) {
    return (
      <Paper>
        <Box pt={1} pb={2} px={2}>
          <Skeleton variant="rounded" width={300} height={50} />
          <Box pt={1}>
            <Skeleton variant="rounded" width={150} height={30} />
          </Box>
        </Box>
      </Paper>
    );
  }

  return (
    <Paper>
      <Box pt={1} pb={2} px={2}>
        <Typography
          variant="h3"
          sx={{
            display: "flex",
          }}
        >
          {isLoading ? <CircularProgress /> : `${totalEggs} ${unit}`}
        </Typography>
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            fontWeight: "300",
            fontSize: "18px",
          }}
        >
          {description}
        </Typography>
      </Box>
    </Paper>
  );
}
