import { useReducer } from "react";

export enum DispatchActionType {
  Disable = "disable",
}

export interface DispatchAction {
  Type: DispatchActionType;
}

export interface GlobalState {
  displayLoadingScreenState: boolean;
}

export const initialDisplayLoadingScreenState: boolean = true;

export function loadingReducer(state: GlobalState, action: DispatchAction) {
  if (action.Type === DispatchActionType.Disable) {
    return {
      ...state,
      displayLoadingScreenState: false,
    };
  }
  throw Error("Unknown action: " + action.Type);
}

export function useGlobalState() {
  const [state, dispatch] = useReducer(loadingReducer, {
    displayLoadingScreenState: initialDisplayLoadingScreenState,
  });

  return {
    state,
    dispatch,
  };
}
