import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslations from "./locales/en-US/translation.json";
import nlTranslations from "./locales/nl-NL/translation.json";

const resources = {
  "en-US": enTranslations,
  "nl-NL": nlTranslations
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en-US",
  fallbackLng: ["en-US", "dev"],
  debug: false,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
