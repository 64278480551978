import { Box, Typography } from "@mui/material";
import { Fragment } from "react";
import { useTranslation } from "react-i18next";
import { JustInstalledMachine } from "../../api/ApiContracts";

interface Props {
  justInstalledMachines: JustInstalledMachine[];
}

export default function MachineTickerPresentation({
  justInstalledMachines,
}: Props) {
  const { t } = useTranslation();

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          bottom: 0,
          width: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Box
          sx={(theme) => ({
            backgroundColor: theme.basic.mobaBlue,
            zIndex: 999,
            position: "relative",
            display: "flex",
          })}
        >
          <Typography
            sx={(theme) => ({
              color: theme.greys.white,
              textTransform: "uppercase",
              padding: theme.spacing(1),
              width: "200px",
              textAlign: "center",
            })}
            variant="body1"
          >
            {t("widgets.machineTicker.description")}
          </Typography>
        </Box>
        <Box
          sx={(theme) => ({
            flexGrow: 1,
            backgroundColor: theme.greys.white,
            alignItems: "center",
            justifyContent: "right",
            display: "flex",
          })}
        >
          <TextTicker>
            <TickerSegment
              justInstalledMachines={justInstalledMachines}
              index={1}
            />
            <TickerSegment
              justInstalledMachines={justInstalledMachines}
              index={2}
            />
          </TextTicker>
        </Box>
      </Box>
    </>
  );
}

function TickerSegment({
  justInstalledMachines,
  index,
}: {
  justInstalledMachines: JustInstalledMachine[];
  index: number;
}) {
  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        paddingLeft: "16px",
        paddingRight: "16px",
      }}
    >
      {justInstalledMachines.map((machine) => {
        return (
          <Fragment key={`${machine.machineId}-fragment-${index}`}>
            <Typography key={`${machine.machineId}-typography-${index}`}>
              {machine.machineId} - {machine.machineType} - {machine.country}
            </Typography>
            <Typography
              key={`${machine.machineId}-dot-${index}`}
              sx={(theme) => ({
                color: theme.basic.mobaBlue,
              })}
            >
              ●
            </Typography>
          </Fragment>
        );
      })}
    </Box>
  );
}

/**
 * To ensure the TextTicker scrolls continously, we require two identical copies of the children.
 * @param children: React.ReactNodes (two Boxes) to be displayed in the TextTicker
 * @returns
 */
function TextTicker({ children }: { children: React.ReactNode }) {
  return (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.greys.white,
        alignItems: "center",
        justifyContent: "left",
        marginLeft: theme.spacing(3),
        display: "flex",
        gap: 2,
        div: {
          whiteSpace: "nowrap",
          overflow: "hidden",
          animation: "child1 120s -120s linear infinite",
          "&:nth-of-type(2)": {
            animation: "child2 120s -60s linear infinite",
          },
          willChange: "transform",
          "@keyframes child1": {
            "0%": {
              transform: "translateX(100%)",
            },
            "100%": {
              transform: "translateX(-100%)",
            },
          },
          "@keyframes child2": {
            "0%": {
              transform: "translateX(0)",
            },
            "100%": {
              transform: "translateX(-200%)",
            },
          },
        },
      })}
    >
      {children}
    </Box>
  );
}
