import { useTranslation } from "react-i18next";
import TotalWidgetBasePresentation from "../total-widget-base/TotalWidgetBasePresentation";
import { useGlobalDashboardData } from "../../../hooks/useGlobalDashboardData";

export default function TotalEggsTodayContainer() {
  const { t } = useTranslation();

  const { isLoading, totalMachines } = useGlobalDashboardData();

  return (
    <TotalWidgetBasePresentation
      totalEggs={totalMachines ?? 0}
      isLoading={isLoading}
      unit={t("widgets.totalMachines.unit")}
      description={t("widgets.totalMachines.description")}
    />
  );
}
