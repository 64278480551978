import { useTranslation } from "react-i18next";
import TotalWidgetBasePresentation from "../total-widget-base/TotalWidgetBasePresentation";
import { useGlobalDashboardData } from "../../../hooks/useGlobalDashboardData";

export default function TotalEggsContainer() {
  const { t } = useTranslation();

  const { isLoading, totalEggs } = useGlobalDashboardData();

  const totalEggsFormatted = totalEggs
    ? Math.round((totalEggs / 1_000_000_000) * 100) / 100
    : 0;

  return (
    <TotalWidgetBasePresentation
      totalEggs={totalEggsFormatted}
      isLoading={isLoading}
      unit={t("widgets.totalEggs.unit")}
      description={t("widgets.totalEggs.description")}
    />
  );
}
