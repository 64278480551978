import { useTranslation } from "react-i18next";
import TotalWidgetBasePresentation from "../total-widget-base/TotalWidgetBasePresentation";
import { useGlobalDashboardData } from "../../../hooks/useGlobalDashboardData";

export default function TotalEggsTodayContainer() {
  const { t } = useTranslation();

  const { isLoading, totalEggsToday } = useGlobalDashboardData();

  const totalEggsFormatted = totalEggsToday
    ? Math.round((totalEggsToday / 1_000_000) * 100) / 100
    : 0;

  return (
    <TotalWidgetBasePresentation
      totalEggs={totalEggsFormatted}
      isLoading={isLoading}
      unit={t("widgets.totalEggsToday.unit")}
      description={t("widgets.totalEggsToday.description")}
    />
  );
}
