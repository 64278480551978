import { createTheme } from "@mui/material/styles";

declare module "@mui/material/styles" {
  interface Theme {
    basic: {
      mobaBlue: string;
      lightBlue: string;
      paleBlue: string;
      mobaOrange: string;
    };
    greys: {
      black: string;
      grey40: string;
      grey67: string;
      grey87: string;
      grey93: string;
      white: string;
    };
    notification: {
      priorityRed: string;
      priorityOrange: string;
      priorityBlue: string;
    };
    egg: {
      dirtGreen: string;
      crackBlue: string;
      leakerYellow: string;
      bloodRed: string;
      specialPurple: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    basic?: {
      mobaBlue?: string;
      lightBlue?: string;
      paleBlue?: string;
      mobaOrange?: string;
    };
    greys?: {
      black?: string;
      grey40?: string;
      grey67?: string;
      grey87?: string;
      grey93?: string;
      white?: string;
    };
    notification?: {
      priorityRed?: string;
      priorityOrange?: string;
      priorityBlue?: string;
    };
    egg?: {
      dirtGreen?: string;
      crackBlue?: string;
      leakerYellow?: string;
      bloodRed?: string;
      specialPurple?: string;
    };
  }
}

export const theme = createTheme({
  typography: {
    fontSize: 14,
    fontFamily: "Ubuntu, sans-serif",
  },
  palette: {
    background: {
      default: "#DDDDDD",
    },
  },
  basic: {
    mobaBlue: "#004082",
    lightBlue: "#0088FF",
    paleBlue: "#EDF6FF",
    mobaOrange: "#F08112",
  },
  greys: {
    black: "#272727",
    grey40: "#666666",
    grey67: "#AAAAAA",
    grey87: "#DDDDDD",
    grey93: "#EEEEEE",
    white: "#FFFFFF",
  },
  notification: {
    priorityRed: "#E60000",
    priorityOrange: "#FFA200",
    priorityBlue: "#0060BF",
  },
  egg: {
    dirtGreen: "#3CB300",
    crackBlue: "#0094D6",
    leakerYellow: "#C9B800",
    bloodRed: "#BF0000",
    specialPurple: "#BF00BF",
  },
});
