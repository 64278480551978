import { Box, Grid } from "@mui/material";
import { MachineMap } from "../components/machine-map";
import { MachineTicker } from "../components/machine-ticker";
import { RealtimeEggs } from "../components/realtime-eggs";
import {
  TotalEggs,
  TotalEggsToday,
  TotalMachines,
  TotalUsers,
} from "../components/totals/";

export default function HomePage() {
  return (
    <>
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={6}>
            <Grid item lg={12}>
              <MachineMap />
            </Grid>
          </Grid>
          <Grid item xs={12} xl={6}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6}>
                <TotalEggs />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TotalMachines />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TotalEggsToday />
              </Grid>
              <Grid item xs={12} lg={6}>
                <TotalUsers />
              </Grid>
              <Grid item xs={12} lg={6}>
                <RealtimeEggs />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
      <MachineTicker />
    </>
  );
}
