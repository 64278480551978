import { useQuery } from "react-query";
import { Queries } from "../api/Queries";
import { getData } from "../api/Services/GlobalDashboardService";

const oncePerHour = 3_600_000;

export function useGlobalDashboardData() {
  const totalsQuery = useQuery([Queries.GetData], {
    queryFn: async () => await getData(),
    refetchInterval: oncePerHour,
  });

  return {
    isLoading: totalsQuery.isLoading,
    justInstalledMachines: totalsQuery.data?.justInstalledMachines,
    totalEggs: totalsQuery.data?.globalTotalEggs,
    totalEggsToday: totalsQuery.data?.globalTotalEggsToday,
    totalUsers: totalsQuery.data?.totalUsers,
    totalMachines: totalsQuery.data?.totalMachines,
    machineLocations: totalsQuery.data?.machineLocations
  };
}
