import { useEffect, useRef } from "react";

/**
 * Repeatedly calls a function with a specified delay.
 * @param callback the function to call
 * @param delayInMilliseconds the delay in milliseconds after which the function will execute
 */
export default function useInterval(
  callback: Function,
  delayInMilliseconds: number
) {
  const savedCallback = useRef(callback);

  /**
   * If the callback changes, remember it.
   */
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const id = setInterval(() => savedCallback.current(), delayInMilliseconds);

    return () => clearInterval(id);
  }, [delayInMilliseconds]);
}
