/**
 * This is a static data set that is used to populate the chart.
 * The data is calculated around an average of 2188 eggs per second (production data as of 2023-03-21).
 * Random deviations are done to make the chart look more interesting.
 * The sum of all deviations of 2188 eggs per second is about 0.
 *
 * This data set is 60 items long.
 */
export const staticData = [
  4319, 1245, 3241, 435, 1878, 629, 695, 3974, 3653, 932, 
  1026, 444, 4268, 1830, 1348, 2804, 174, 1153, 972, 4063, 
  2534, 878, 4134, 2207, 2366, 4274, 1669, 319, 2585, 2982, 
  1111, 3249, 1929, 3705, 3729, 719, 545, 3824, 573, 3961, 
  4034, 3423, 2586, 551, 3531, 1775, 1898, 989, 3983, 183, 
  689, 1990, 4060, 2218, 488, 3441, 1487, 1034, 2446, 5704
];
