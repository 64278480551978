import { Location } from "../../api/ApiContracts";

export const staticMobaOffices: Array<Location> = [
  { id: 100000, latitude: 42.49670143468726, longitude: -83.56642045767099 },
  { id: 100001, latitude: 52.1514196243281, longitude: 5.593580254606474 },
  { id: 100002, latitude: 52.158365290876574, longitude: 5.60043068713914 },
  { id: 100003, latitude: 52.66143204784954, longitude: 8.208381998793657 },
  { id: 100004, latitude: 34.69399904247279, longitude: 135.1960453985086 },
  { id: 100005, latitude: 52.420276797530775, longitude: 4.856070241118027 },
  { id: 100006, latitude: 25.25319383928506, longitude: 55.36569424532476 },
  { id: 100007, latitude: 3.127194665171087, longitude: 101.64328756878002 },
  { id: 100008, latitude: 38.98333509593413, longitude: 116.96763918741217 },
  { id: 100009, latitude: 45.56197776576683, longitude: 9.311436069830995 },
  {
    id: 100010,
    latitude: 48.54999281343256,
    longitude: -2.7985889454596498,
  },
  {
    id: 100011,
    latitude: 26.098265872632492,
    longitude: -80.36556300158124,
  },
  { id: 100012, latitude: 52.69475221493421, longitude: -2.477608187712211 },
];