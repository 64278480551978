import { useGlobalDashboardData } from "../../hooks/useGlobalDashboardData";
import MachineTickerPresentation from "./MachineTickerPresentation";

export default function MachineTickerContainer() {
  const { justInstalledMachines } = useGlobalDashboardData();

  return (
    <MachineTickerPresentation
      justInstalledMachines={justInstalledMachines ?? []}
    />
  );
}
